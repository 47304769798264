<template>
  <v-form
    v-model="valid"
    autocomplete="off"
    aria-label="Formular: Grundstück suchen"
    ref="form"
  >
    <v-autocomplete
      v-model="amtsgericht"
      auto-select-first
      :items="amtsgerichte"
      item-text="name"
      item-value="id"
      return-object
      label="Amtsgericht"
      :rules="[rules.requiredAmtsgericht]"
    ></v-autocomplete>
    <v-autocomplete
      v-model="grundbuchbezirk"
      auto-select-first
      :items="grundbuchbezirke"
      item-text="name"
      item-value="id"
      return-object
      label="Grundbuch von"
      :rules="[rules.requiredGrundbuchbezirk]"
    >
      <template v-slot:item="data">
          <v-list-item-content>
            <v-list-item-title v-html="data.item.name"></v-list-item-title>
            <v-list-item-subtitle v-html="data.item.gbbz"></v-list-item-subtitle>
          </v-list-item-content>
      </template>
    </v-autocomplete>
    <v-text-field
      v-model="blatt"
      label="Blatt"
      maxlength="10"
      :rules="[rules.requiredBlatt, rules.alphanumeric]"
    />
    <v-combobox
      @update:search-input="updateLfdnrbestandsvz"
      :items="lfdnrs"
      label="lfd.Nr."
      maxlength="10"
      :menu-props="menuProps"
      ref="combobox"
      :rules="[rules.requiredLfdnrbestandsvz]"
      :search-input="lfdnrbestandsvz"
      @keydown.enter.native.stop="submit"
    >
      <template v-slot:no-data>
        <v-list-item v-if="lfdnrbestandsvz">
          <v-list-item-content>
            <v-list-item-title>
              Keine Ergebnisse für "<strong>{{ lfdnrbestandsvz }}</strong>". Drücken Sie <kbd>enter</kbd>, um fortzufahren.
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-combobox>
  </v-form>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual'
import _debounce from 'lodash/debounce'

import {AmtsgerichtRepository} from '../../repositories/amtsgericht-repository';
import {GrundbuchbezirkRepository} from '../../repositories/grundbuchbezirk-repository';
import {GrundstueckRepository} from "../../repositories/grundstueck-repository";

export default {
  props: {
    value: {},
    versionId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      amtsgericht: null,
      amtsgerichte: [],
      grundbuchbezirk: null,
      grundbuchbezirke: [],
      blatt: null,
      lfdnrbestandsvz: null,
      lfdnrs: [],
      valid: true,
      rules: {
        alphanumeric: value => {
          const pattern = /^[A-Za-z0-9]*$/;
          return pattern.test(value) || 'Es sind keine Umlaute und Sonderzeichen erlaubt.';
        },
        requiredAmtsgericht: value => !_isEmpty(value) || 'Geben Sie ein Amtsgericht ein.',
        requiredGrundbuchbezirk: value => !_isEmpty(value) || 'Geben Sie den Grundbuchbezirk ein.',
        requiredBlatt: value => !!value || 'Geben Sie die Blattnummer ein.',
        requiredLfdnrbestandsvz: () => !!this.lfdnrbestandsvz || 'Geben Sie die laufende Nummer ein.'
      }
    };
  },
  computed: {
    local () {
      return {
        amtsgericht: this.amtsgericht,
        grundbuchbezirk: this.grundbuchbezirk,
        blatt: this.blatt,
        lfdnrbestandsvz: this.lfdnrbestandsvz
      }
    },
    menuProps () {
      return !this.lfdnrs ? {value: false} : {}
    }
  },
  watch: {
    async versionId (value) {
      if (value) {
        const found = await this.searchAmtsgerichte({
          versionId: value
        })
        this.amtsgerichte = found.sort(this._sortByName)
      }
    },
    async amtsgericht (value) {
      if (value) {
        const found = await this.searchGrundbuchbezirke({
          amtsgerichtId: value.id,
          versionId: this.versionId
        })
        this.grundbuchbezirke = found.sort(this._sortByName)
      }
      this.grundbuchbezirk = null
      this.blatt = null
      this.lfdnrbestandsvz = null
      this.lfdnrs = []
      this.resetValidation()
    },
    grundbuchbezirk () {
      this.blatt = null
      this.lfdnrbestandsvz = null
      this.lfdnrs = []
      this.resetValidation()
    },
    blatt: _debounce(async function (value) {
      if (value) {
        const grundstuecke = await this.searchGrundstuecke({
          gbbz: this.grundbuchbezirk.gbbz,
          blatt: this.blatt,
          versionId: this.versionId
        })

        this.lfdnrs = grundstuecke
          .map(grundstueck => grundstueck.text)
          .map(text => {
            const parts = text.split('/').slice(3)
            return parts.join('/').trim()
          })
      }
    }, 450, {trailing: true}),
    value: {
      deep: true,
      handler (value) {
        if (value.amtsgericht) {
          if (!_isEqual(value.amtsgericht, this.amtsgericht)) {
            this.amtsgericht = _cloneDeep(value.amtsgericht)
          }
        } else {
          this.amtsgericht = null
        }

        if (value.grundbuchbezirk) {
          if (!_isEqual(value.grundbuchbezirk, this.grundbuchbezirk)) {
            this.grundbuchbezirk = _cloneDeep(value.grundbuchbezirk)
          }
        } else {
          this.grundbuchbezirk = null
        }

        if (value.blatt) {
          if (value.blatt !== this.blatt) {
            this.blatt = value.blatt
          }
        } else {
          this.blatt = null
        }

        if (value.lfdnrbestandsvz) {
          if (value.lfdnrbestandsvz !== this.lfdnrbestandsvz) {
            this.lfdnrbestandsvz = value.lfdnrbestandsvz
          }
        } else {
          this.lfdnrbestandsvz = null
        }
      }
    },
    local (value) {
      if (!_isEqual(value, this.value)) {
        this.$emit('input', _cloneDeep(value))
      }
    }
  },
  methods: {
    _isEmpty,
    _sortByName (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    },
    async searchAmtsgerichte (query) {
      const { data } = await AmtsgerichtRepository.search({
        ...query,
        size: 1000
      });
      return data.content
    },
    async searchGrundbuchbezirke (query) {
      const { data } = await GrundbuchbezirkRepository.search({
        ...query,
        size: 1000
      });
      return data.content
    },
    async searchGrundstuecke (query) {
      const { data } = await GrundstueckRepository.search({
        ...query,
        size: 1000
      })
      return data.content
    },
    submit () {
      this.$refs.combobox.isMenuActive = false
      this.validate()
      this.$emit('submit')
    },
    validate () {
      return this.$refs.form.validate();
    },
    resetValidation () {
      this.$refs.form.resetValidation();
    },
    updateLfdnrbestandsvz (value) {
      this.lfdnrbestandsvz = value
    }
  }
};
</script>
