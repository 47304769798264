<template>
  <div>
    <VFormSearchGrundstueck
      v-model="search"
      class="mb-4"
      ref="form"
      :version-id="versionId"
      @submit="onSearchGrundstueck"
    />
    <VActionRow>
      <VBtnResponsive
        color="primary"
        depressed
        :disabled="loading || (user.hasAnyRole([ROLE_BNOTK]) && !amtstaetigkeitId)"
        :loading="loading"
        tile
        @click="onSearchGrundstueck"
      >
        Grundst&uuml;ck suchen
      </VBtnResponsive>
      <VBtnResponsive
        color="grey lighten-3"
        depressed
        tile
        @click="reset"
      >
        Eingabe l&ouml;schen
      </VBtnResponsive>
    </VActionRow>
    <VDialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <component
        :is="component"
        :amtstaetigkeit-id="amtstaetigkeitId"
        :grundstueck-id="searchResult.id"
        :query="search"
        @submit="onSubmit"
        @cancel="onCancel"
      />
    </VDialog>
  </div>
</template>

<script>
import GrundstueckFound from './GrundstueckFound';
import GrundstueckNotFound from './GrundstueckNotFound';
import VActionRow from '../../../../components/VActionRow';
import VBtnResponsive from '@/components/VBtnResponsive';
import VDialog from '../../../../components/VDialog';
import VFormSearchGrundstueck from '@/components/VFormSearchGrundstueck';

import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import _throttle from 'lodash/throttle';

import {ROLE_BNOTK} from '../../../../config';
import {GrundstueckRepository} from '../../../../repositories/grundstueck-repository';

export default {
  components: {
    VActionRow,
    VBtnResponsive,
    VDialog,
    VFormSearchGrundstueck,
    GrundstueckFound,
    GrundstueckNotFound
  },
  props: {
    value: {},
    amtstaetigkeitId: {
      type: String,
      required: false,
      default: null
    },
    user: {
      type: Object,
      required: true,
      default: () => ({})
    },
    versionId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      ROLE_BNOTK,
      search: {},
      searchResult: {},
      component: null,
      dialog: false,
      loading: false
    };
  },
  watch: {
    search: {
      handler (search) {
        if (!_isEqual(this.value, search)) {
          this.$emit('input', search);
        }
      },
      deep: true
    },
    value: {
      handler (value) {
        if (!_isEqual(this.search, value)) {
          this.search = value;
        }
      },
      deep: true
    }
  },
  methods: {
    onSearchGrundstueck: _throttle(async function () {
      if (this.validate()) {
        const searchResult = await this.searchGrundstueck({
          versionId: this.versionId,
          gbbz: _get(this.search, 'grundbuchbezirk.gbbz'),
          blatt: this.search.blatt,
          lfdnrbestandsvz: this.search.lfdnrbestandsvz,
        });
        this.showResultDialog(this.search, searchResult);
      }
    }, 450, { trailing: false }),

    async searchGrundstueck (query) {
      this.loading = true;
      try {
        const { data } = await GrundstueckRepository.search(query);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      } finally {
        this.loading = false;
      }
    },

    showResultDialog (query, searchResult) {
      if (searchResult.empty) {
        this.searchResult = query;
        this.component = 'GrundstueckNotFound';
      } else {
        this.searchResult = searchResult.content[0];
        this.component = 'GrundstueckFound'
      }
      this.dialog = true;
    },

    onSubmit (event) {
      this.dialog = false;
      this.resetSearchLfdNr();
      this.$emit('submit', event);
    },

    onCancel () {
      this.dialog = false;
      this.resetSearchLfdNr();
    },

    validate () {
      return this.$refs.form.validate();
    },

    resetValidation () {
      this.$refs.form.resetValidation();
    },

    reset () {
      this.search = {};
      this.resetValidation();
    },

    resetSearchLfdNr () {
      this.search.lfdnrbestandsvz = '';
      this.resetValidation();
    }
  }
};
</script>
