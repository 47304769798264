import { DefaultRepository } from './default-repository';

const resource = '/grundstueck';
const repository = DefaultRepository.getInstance();

export const GrundstueckRepository = {
  findById (id, amtstaetigkeitId, config = {}) {
    return repository.get(`${resource}/${id}`, {
      params: {
        amtstaetigkeitId: amtstaetigkeitId ? amtstaetigkeitId : undefined
      },
      ...config
    });
  },
  search (query, config = {}) {
    return repository.get(resource, {
      params: query,
      ...config
    });
  }
};
