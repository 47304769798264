<template>
  <div v-if="grundstueck.id">
    <VDialogContent
      title="Grundstück gefunden"
      icon="mdi-check-circle"
      color="green"
      accent="darken-4"
    >
      <p v-if="grundstueck.buchungsart_included">
        Vergleichen Sie die Angaben mit Ihrem Auszug aus dem Bestandsverzeichnis.
      </p>
      <p v-else>
        Für Grundstücke mit der Buchungsart <strong> {{ grundstueck.buchungsart }}</strong> besteht
        <strong>kein</strong>
        Vorkaufsrecht.
      </p>

      <v-text-field
        label="Grundstück / Erbbaurecht"
        readonly
        :value="grundstueck.text"
      ></v-text-field>
      <v-text-field
        label="Buchungsart"
        readonly
        :value="grundstueck.buchungsart"
      ></v-text-field>
      <v-subheader>Flurst&uuml;cke</v-subheader>
      <v-data-table
        disable-sort
        :headers="headers"
        hide-default-footer
        :hide-default-header="$vuetify.breakpoint.xs"
        :items="grundstueck.flurstuecke"
        no-data-text="Keine Flurstücke vorhanden."
      ></v-data-table>

      <v-form
        v-if="grundstueck.buchungsart_included"
        ref="form"
        @submit.prevent
      >
        <v-radio-group
          v-model="confirmed"
          label="Angaben bestätigen"
        >
          <v-radio
            color="green darken-4"
            :value="true"
          >
            <template #label>
              <span class="green--text text--darken-4">Grundst&uuml;ck/Erbbaurecht stimmt mit Bestandsverzeichnis &uuml;berein</span>
            </template>
          </v-radio>
          <v-radio
            color="red accent-4"
            :value="false"
          >
            <template #label>
              <span class="red--text text--accent-4">Grundst&uuml;ck/Erbbaurecht stimmt nicht mit Bestandsverzeichnis &uuml;berein</span>
            </template>
          </v-radio>
        </v-radio-group>
        <VDatePickerLastPropertyChange
          v-model="grundbuchModified"
          :disabled="confirmed || confirmed == null"
          @submit="onSubmit"
        ></VDatePickerLastPropertyChange>
      </v-form>

      <template
        v-if="grundstueck.buchungsart_included"
        #actions
      >
        <v-spacer></v-spacer>
        <VBtnResponsive
          color="grey lighten-3"
          depressed
          tile
          @click="onCancel"
        >
          Abbrechen
        </VBtnResponsive>
        <VBtnResponsive
          color="primary"
          depressed
          tile
          @click="onSubmit"
        >
          Grundstück übernehmen
        </VBtnResponsive>
      </template>
      <template
        v-else
        #actions
      >
        <v-spacer></v-spacer>
        <VBtnResponsive
          color="primary"
          text
          @click="$emit('cancel')"
        >
          Ok
        </VBtnResponsive>
      </template>
    </VDialogContent>
  </div>
</template>

<script>
import VBtnResponsive from '../../../../components/VBtnResponsive';
import VDialogContent from '../../../../components/VDialogContent';
import VDatePickerLastPropertyChange from '../VDatePickerLastPropertyChange';

import _cloneDeep from 'lodash/cloneDeep';
import _throttle from 'lodash/throttle';

import {GrundstueckRepository} from '../../../../repositories/grundstueck-repository';

export default {
  components: {
    VBtnResponsive,
    VDialogContent,
    VDatePickerLastPropertyChange
  },
  props: {
    amtstaetigkeitId: {
      type: String,
      required: false,
      default: null
    },
    grundstueckId: {
      type: String,
      required: true,
      default: null
    }
  },
  data() {
    return {
      confirmed: true,
      headers: [
        {
          text: 'Gemarkung',
          value: 'gemarkung',
          align: 'left'
        },
        {
          text: 'Flur',
          value: 'flur'
        },
        {
          text: 'Flurstück',
          value: 'flstnr'
        }
      ],
      grundstueck: {},
      grundbuchModified: null
    };
  },
  watch: {
    grundstueckId: {
      async handler(id) {
        if (id) {
          this.grundstueck = await this.fetchGrundstueckById(id, this.amtstaetigkeitId);
        }
      },
      immediate: true
    }
  },
  methods: {
    async fetchGrundstueckById(id, amtstaetigkeitId) {
      try {
        const {data} = await GrundstueckRepository.findById(id, amtstaetigkeitId);
        return data;
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    onSubmit: _throttle(function () {
      if (this.$refs.form.validate()) {
        const grundstueck = _cloneDeep(this.grundstueck);
        grundstueck.grundbuch_modified = this.grundbuchModified;
        this.reset();
        this.$emit('submit', {
          grundstueck: grundstueck
        });
      }
    }, 450, {trailing: false}),

    onCancel() {
      this.reset();
      this.$emit('cancel');
    },

    reset() {
      this.confirmed = true;
      this.grundbuchModified = null;
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<style scoped>
>>> .v-data-table td,
>>> .v-data-table th,
>>> .v-subheader {
  padding-left: 0 !important;
}
</style>
