<template>
  <v-select
    v-bind="$attrs"
    v-model="id"
    :items="amtstaetigkeiten"
    item-text="amtstitel"
    item-value="id"
    label="Amtstitel"
    :rules="rules"
  >
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title>{{ item.amtstitel }}</v-list-item-title>
        <v-list-item-subtitle>{{ formatName(item) }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{ item.strasseHn }}</v-list-item-subtitle>
        <v-list-item-subtitle>{{ formatLocation(item) }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-select>
</template>

<script>
import {requiredRule} from '../../common/lang';

import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';

export default {
  props: {
    amtstaetigkeiten: {
      type: Array,
      required: false,
      default: () => ([]),
      validator (value) {
        return _isEmpty(value)
          || value.every(item => _has(item, 'id') && _has(item, 'amtstitel'));
      }
    },
    value: {
      type: String,
      required: false,
      default: undefined
    }
  },
  data () {
    return {
      id: this.value,
      rules: [
        requiredRule('Wählen Sie eine Amtstätigkeit aus.')
      ]
    };
  },
  watch: {
    id (id) {
      if (this.value !== id) {
        this.$emit('input', id);
      }
    },
    value (value) {
      if (this.id !== value) {
        this.id = value;
      }
    }
  },
  methods: {
    formatName(notarialFunction) {
      return [notarialFunction.title, notarialFunction.vorname, notarialFunction.name]
          .filter(Boolean)
          .join(" ");
    },
    formatLocation(notarialFunction) {
      return [notarialFunction.plz, notarialFunction.ort]
        .filter(Boolean)
        .join(" ")
    }
  }
};
</script>
