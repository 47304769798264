<template>
  <v-alert
    v-bind="{...$props, ...$attrs}"
    v-on="$listeners"
    :border="border"
    :colored-border="colorBorder"
    :color="color"
    :dense="dense"
    :elevation="elevation"
    :tile="tile"
  >
    <slot></slot>
  </v-alert>
</template>

<script>
export default {
  props: {
    border: {
      default: 'left'
    },
    color: {
      default: 'primary'
    },
    colorBorder: {
      default: true
    },
    dense: {
      default: true
    },
    elevation: {
      default: '1'
    },
    tile: {
      default: true
    }
  }
};
</script>
