import { DefaultRepository } from './default-repository';

const resource = '/amtsgericht';
const repository = DefaultRepository.getInstance();

export const AmtsgerichtRepository = {
  search (query, config = {}) {
    return repository.get(resource, {
      params: query,
      ...config
    });
  }
};
