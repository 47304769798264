<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    min-width="290px"
    offset-y
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        v-mask="dateMask"
        autocomplete="new-password"
        :autofocus="autofocus"
        :disabled="disabled"
        :error="error"
        hint="DD.MM.YYYY"
        :label="label"
        persistent-hint
        prepend-icon="mdi-calendar"
        ref="textField"
        :rules="computedRules"
        validate-on-blur
        @click:prepend="on.click"
        @blur="onBlur"
        @keyup.enter.native="onSubmit"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      color="primary"
      locale="de-de"
      no-title
      :max="max"
      scrollable
      @input="onSelect"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { mask } from 'vue-the-mask';
import { formatDate } from '../../common/lang';

const PATTERN_ISO_DATE = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
const PATTERN_GERMAN_DATE = /^\s*(3[01]|[12][0-9]|0[1-9])\.(1[012]|0[1-9])\.((?:19|20)\d{2})\s*$/;

export default {
  directives: {
    mask,
  },
  props: {
    value: {
      type: String,
      validator (value) {
        return value ? PATTERN_ISO_DATE.test(value) : true;
      }
    },
    autofocus: {
      type: Boolean
    },
    disabled: {
      type: Boolean
    },
    label: {},
    rules: {
      default () {
        return [];
      }
    },
    max: {
      type: String,
      validator (value) {
        return value ? PATTERN_ISO_DATE.test(value) : true;
      }
    }
  },
  data () {
    return {
      menu: false,
      date: this.value,
      dateFormatted: '',
      dateMask: '##.##.####',
      error: false
    };
  },
  computed: {
    computedRules () {
      return !this.disabled
        ? [
          ...this.rules,
          value => {
            let isValid = false;
            if (this.isValidGermanDate(value)) {
              isValid = this.max
                ? new Date(this.parseFormattedDate(value)) <= new Date(this.max)
                : true;
            }
            return isValid || 'Der eingegebene Wert ist ungültig.';
          }
        ]
        : undefined;
    }
  },
  methods: {
    onSubmit () {
      if (this.isValidGermanDate(this.dateFormatted)) {
        this.error = false;
        this.date = this.parseFormattedDate(this.dateFormatted);
        this.menu = false;
        // Warten bis Änderungen am Model durchgeführt wurden, dann submit event werfen
        this.$nextTick(() => {
          this.$emit('submit');
        });
      } else {
        this.error = true;
      }
    },
    onBlur () {
      if (this.isValidGermanDate(this.dateFormatted)) {
        this.error = false;
        this.date = this.parseFormattedDate(this.dateFormatted);
      }
    },
    onSelect () {
      this.menu = false;
      this.resetValidation();
    },
    isValidGermanDate (value) {
      return PATTERN_GERMAN_DATE.test(value);
    },
    parseFormattedDate (value) {
      let date = null;
      if (value) {
        if (this.isValidGermanDate(value)) {
          const [day, month, year] = value.split('.');
          date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        } else {
          throw new Error(`Input ${value} is invalid date.`);
        }
      }
      return date;
    },
    resetValidation () {
      this.$refs.textField.resetValidation();
    }
  },
  watch: {
    date (date) {
      this.dateFormatted = formatDate(date);
      if (this.value !== date) {
        this.$emit('input', date);
      }
    },
    value (value) {
      if (this.date !== value) {
        this.date = value;
      }
    }
  }
};
</script>
