<template>
  <div>
    <p>
      Bitte tragen Sie die Adressdaten der Verkäufer ein.
    </p>
    <v-form
      v-model="valid"
      class="mb-4"
      ref="form"
      @submit.prevent
    >
      <v-text-field
        v-model.trim="address.given_name"
        autocomplete="new-password"
        label="Vorname (nat. Person)"
        maxlength="255"
        @keyup.enter.native="addAddress"
      />
      <v-text-field
        v-model.trim="address.name"
        autocomplete="new-password"
        label="Name*"
        maxlength="255"
        :rules="rules.name"
        @keyup.enter.native="addAddress"
      />
      <v-text-field
        v-model.trim="address.street"
        autocomplete="new-password"
        label="Straße*"
        maxlength="255"
        :rules="rules.street"
        @keyup.enter.native="addAddress"
      />
      <v-text-field
        v-model.trim="address.street_number"
        autocomplete="new-password"
        label="Hausnr.*"
        maxlength="255"
        :rules="rules.street_number"
        @keyup.enter.native="addAddress"
      />
      <v-text-field
        v-model.trim="address.postal_code"
        autocomplete="new-password"
        label="Postleitzahl*"
        maxlength="10"
        :rules="rules.postal_code"
        @keyup.enter.native="addAddress"
      />
      <v-text-field
        v-model.trim="address.locality"
        autocomplete="new-password"
        label="Ort*"
        maxlength="255"
        :rules="rules.locality"
        @keyup.enter.native="addAddress"
      />
      <span class="text-caption">* Pflichtfelder</span>
    </v-form>
    <VActionRow>
      <VBtnResponsive
        color="primary"
        depressed
        tile
        @click="addAddress"
      >
        Adresse hinzuf&uuml;gen
      </VBtnResponsive>
      <VBtnResponsive
        color="grey lighten-3"
        depressed
        tile
        @click="resetForm"
      >
        Eingabe l&ouml;schen
      </VBtnResponsive>
    </VActionRow>
    <v-data-table
      v-model="selectedAdressen"
      class="mb-4"
      disable-sort
      disable-pagination
      :headers="headers"
      hide-default-footer
      :items="items"
      item-key="id"
      :no-data-text="NO_DATA_TEXT_ADRESSEN"
      show-select
    ></v-data-table>
    <VBtnResponsive
      color="grey lighten-3"
      depressed
      tile
      @click="removeSelected"
    >
      Auswahl l&ouml;schen
    </VBtnResponsive>
  </div>
</template>

<script>
import VActionRow from '../../../../components/VActionRow';
import VBtnResponsive from '../../../../components/VBtnResponsive';

import _cloneDeep from 'lodash/cloneDeep';
import _omit from 'lodash/omit';
import _throttle from 'lodash/throttle';
import _uniqueId from 'lodash/uniqueId';

import {requiredRule} from '../../../../common/lang';

const LIMIT_ADRESSEN = 50;
const NO_DATA_TEXT_ADRESSEN = 'Keine Adressen eingegeben.';
const WARNING_LIMIT_ADRESSEN = `Es dürfen maximal ${LIMIT_ADRESSEN} Adressen hinzugefügt werden.`;

export default {
  components: {
    VActionRow,
    VBtnResponsive
  },
  props: {
    value: {}
  },
  data () {
    return {
      address: {},
      headers: [
        {
          text: 'Vorname (nat. Person)',
          value: 'given_name'
        },
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Straße',
          value: 'street'
        },
        {
          text: 'Hausnr.',
          value: 'street_number'
        },
        {
          text: 'Postleitzahl',
          value: 'postal_code'
        },
        {
          text: 'Ort',
          value: 'locality'
        }
      ],
      rules: {
        name: [
          requiredRule('Geben Sie den Namen ein.')
        ],
        postal_code: [
          requiredRule('Geben Sie die Postleitzahl ein.')
        ],
        locality: [
          requiredRule('Geben Sie den Ort ein.')
        ],
        street: [
          requiredRule('Geben Sie die Strasse ein.')
        ],
        street_number: [
          requiredRule('Geben Sie die Hausnummer ein.')
        ]
      },
      selectedAdressen: [],
      valid: true,
      NO_DATA_TEXT_ADRESSEN
    };
  },
  computed: {
    adressen () {
      return this.value;
    },
    items () {
      return this.adressen.map(address => {
        return {
          ...address,
          id: _uniqueId('address_') // items brauchen eine id
        };
      });
    }
  },
  methods: {
    resetForm () {
      this.address = {};
      this.$refs.form.resetValidation();
    },
    addAddress: _throttle(async function () {
      if (this.$refs.form.validate()) {
        if (this.adressen.length === LIMIT_ADRESSEN) {
          this.$store.commit('SET_SNACKBAR_TEXT', WARNING_LIMIT_ADRESSEN);
        } else {
          const clone = _cloneDeep(this.adressen);
          clone.push(this.address);
          this.resetForm();
          this.$emit('input', clone);
        }
      }
    }, 450, { trailing: false }),
    removeSelected () {
      const adressen = this.items
        .filter(item => {
          // selektierte adressen ignorieren
          return !this.selectedAdressen.some(selected => selected.id === item.id);
        })
        .map(item => {
          // id aus adressen objekt entfernen
          // id wurde hier nur für die data-table hinzugefügt
          return _omit(_cloneDeep(item), 'id');
        });

      this.$emit('input', adressen);
      this.selectedAdressen = [];
    }
  }
};
</script>
