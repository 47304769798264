<template>
  <VDialogContent
    title="Grundstück unbekannt"
    icon="mdi-close-circle"
    color="red"
    accent="accent-4"
  >
    <p>
      Das angegebene Grundst&uuml;ck/Erbbaurecht ist im amtlichen Verzeichnis <strong
      class="text-underline">unbekannt</strong>.
    </p>
    <p>
      Bitte pr&uuml;fen Sie Ihre Eingabe und geben Sie das Datum der letzten &Auml;nderung des
      Grundbuchblattes ein.
    </p>
    <v-text-field
      label="Grundstück / Erbbaurecht"
      readonly
      :value="grundstueckText(query)"
    ></v-text-field>
    <v-form
      v-model="valid"
      ref="form"
      @submit.prevent
    >
      <VDatePickerLastPropertyChange
        v-model="grundbuchModified"
        @submit="onSubmit"
      ></VDatePickerLastPropertyChange>
    </v-form>
    <template #actions>
      <v-spacer></v-spacer>
      <VBtnResponsive
        color="grey lighten-3"
        depressed
        tile
        @click="onCancel"
      >
        Abbrechen
      </VBtnResponsive>
      <VBtnResponsive
        color="primary"
        depressed
        tile
        @click="onSubmit"
      >
        Grundstück übernehmen
      </VBtnResponsive>
    </template>
  </VDialogContent>
</template>

<script>
import VBtnResponsive from '../../../../components/VBtnResponsive';
import VDialogContent from '../../../../components/VDialogContent';
import VDatePickerLastPropertyChange from '../VDatePickerLastPropertyChange';

import _cloneDeep from 'lodash/cloneDeep';
import _throttle from 'lodash/throttle';

import {text as grundstueckText} from '../../../../common/grundstueck';

export default {
  components: {
    VBtnResponsive,
    VDialogContent,
    VDatePickerLastPropertyChange
  },
  props: {
    query: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    return {
      valid: true,
      grundbuchModified: ''
    };
  },
  methods: {
    reset () {
      this.$refs.form.resetValidation();
      this.grundbuchModified = null;
    },

    onSubmit: _throttle(function () {
      if (this.$refs.form.validate()) {
        const grundstueck = _cloneDeep(this.query);
        grundstueck.grundbuch_modified = this.grundbuchModified;
        this.reset();
        this.$emit('submit', {
          grundstueck: grundstueck
        });
      }
    }, 450, { trailing: false }),

    onCancel () {
      this.reset();
      this.$emit('cancel');
    },

    grundstueckText
  }
};
</script>
