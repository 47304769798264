<template>
  <div>
    <p>
      Bitte w&auml;hlen Sie die vorhandenen Empfangsvollmachten aus.
    </p>
    <div class="mb-4">
      <p>
        Empfangsvollmacht zur <strong>Entgegennahme der Erklärung</strong>
      </p>
      <v-checkbox
        v-model="vollmachten.vollmachtVerzicht"
        color="primary"
        hide-details
      >
        <template #label>
          <strong class="black--text">des Verzichts auf das Vorkaufsrecht</strong>
        </template>
      </v-checkbox>
    </div>
    <div>
      <div>Empfangsvollmacht zur <strong>Entgegennahme einer Abschrift</strong> der Erklärung</div>
      <v-checkbox
        v-model="vollmachten.vollmachtAbschriftVerzicht"
        color="primary"
        hide-details
      >
        <template #label>
          <strong class="black--text">des Verzichts auf das Vorkaufsrecht</strong>
        </template>
      </v-checkbox>
      <v-checkbox
        v-model="vollmachten.vollmachtAbschriftAusuebung"
        color="primary"
        hide-details
      >
        <template #label>
          <strong class="black--text">der Ausübung des Vorkaufsrechts</strong>
        </template>
      </v-checkbox>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';

export default {
  props: {
    value: {}
  },
  data () {
    return {
      vollmachten: {}
    };
  },
  watch: {
    value: {
      handler (value) {
        if (!_isEqual(this.vollmachten, value)) {
          this.vollmachten = _cloneDeep(this.value);
        }
      },
      deep: true,
      immediate: true
    },
    vollmachten: {
      handler (vollmachten) {
        if (!_isEqual(this.value, vollmachten)) {
          this.$emit('input', vollmachten);
        }
      },
      deep: true
    }
  }
};
</script>
