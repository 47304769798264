<template>
  <VDialog
    v-model="show"
    v-bind="$attrs"
  >
    <VDialogContent
      :title="title"
    >
      <v-window v-model="step">
        <v-window-item :value="1">
          <VFormMandate v-model="vollmachten"/>
        </v-window-item>
        <v-window-item :value="2">
          <VFormPropertyOwners v-model="adressen"/>
        </v-window-item>
      </v-window>
      <template #actions>
        <v-spacer></v-spacer>
        <VBtnResponsive
          v-show="step > 1"
          color="grey lighten-3"
          depressed
          @click="step--"
        >
          Zurück
        </VBtnResponsive>
        <VBtnResponsive
          color="grey lighten-3"
          depressed
          tile
          @click="onCancel"
        >
          Abbrechen
        </VBtnResponsive>
        <VBtnResponsive
          v-show="step < steps.length"
          color="primary"
          depressed
          tile
          @click="step++"
        >
          Weiter
        </VBtnResponsive>
        <VBtnResponsive
          v-show="step === steps.length"
          color="primary"
          depressed
          tile
          @click="onSubmit"
        >
          Auskunft erstellen
        </VBtnResponsive>
      </template>
    </VDialogContent>
  </VDialog>
</template>

<script>
import VBtnResponsive from '../../../../components/VBtnResponsive';
import VDialog from '../../../../components/VDialog';
import VDialogContent from '../../../../components/VDialogContent';
import VFormMandate from './VFormMandate';
import VFormPropertyOwners from './VFormPropertyOwners';

import _cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';

const WARNING_ADDRESSES_EMPTY = 'Es wurden keine Adressen eingegeben.';
const TITLE = 'Auskunft erstellen';

export default {
  components: {
    VBtnResponsive,
    VDialog,
    VDialogContent,
    VFormMandate,
    VFormPropertyOwners
  },
  props: {
    auskunft: {},
    value: {}
  },
  data () {
    return {
      adressen: [],
      vollmachten: {},
      show: this.value,
      step: 1,
      steps: [
        {
          id: 1,
          title: 'Empfangsvollmachten auswählen'
        },
        {
          id: 2,
          title: 'Verkäuferaddresse(n) eingeben'
        }
      ]
    };
  },
  computed: {
    title () {
      const step = this.steps.find(step => step.id === this.step);
      return step ? `${TITLE} - ${step.title}` : TITLE;
    }
  },
  watch: {
    auskunft: {
      handler (auskunft) {
        const vollmachten = this.getMandates(auskunft);
        const adressen = this.getPropertyOwners(auskunft);

        if (!_isEqual(vollmachten, this.vollmachten)) {
          this.vollmachten = vollmachten;
        }
        if (!_isEqual(adressen, this.adressen)) {
          this.adressen = adressen;
        }
      },
      deep: true,
      immediate: true
    },
    value (value) {
      if (this.show !== value) {
        this.show = value;
      }
    },
    show (show) {
      if (this.value !== show) {
        this.$emit('input', show);
      }
    }
  },
  methods: {
    onCancel () {
      this.$emit('cancel');
      this.reset();
    },
    onSubmit () {
      if (this.adressen.length === 0) {
        this.$store.commit('SET_SNACKBAR_TEXT', WARNING_ADDRESSES_EMPTY);
      } else {
        this.$emit('submit', {
          adressen: _cloneDeep(this.adressen),
          vollmachten: _cloneDeep(this.vollmachten)
        });
        this.reset();
      }
    },
    reset () {
      this.step = 1;
      this.adressen = this.getPropertyOwners(this.auskunft);
      this.vollmachten = this.getMandates(this.auskunft);
    },
    getPropertyOwners (auskunft) {
      return _cloneDeep(_get(auskunft, 'verkaeufer', []));
    },
    getMandates (auskunft) {
      return {
        vollmachtVerzicht: auskunft.vollmachtVerzicht === undefined || auskunft.vollmachtVerzicht === null
          ? true
          : auskunft.vollmachtVerzicht,
        vollmachtAbschriftVerzicht: auskunft.vollmachtAbschriftVerzicht === undefined || auskunft.vollmachtAbschriftVerzicht === null
          ? false
          : auskunft.vollmachtAbschriftVerzicht,
        vollmachtAbschriftAusuebung: auskunft.vollmachtAbschriftAusuebung === undefined || auskunft.vollmachtAbschriftAusuebung === null
          ? true
          : auskunft.vollmachtAbschriftAusuebung
      };
    }
  }
};
</script>
