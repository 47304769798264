<template>
  <VDatePickerMenu
    v-model="lastChange"
    :disabled="disabled"
    :label="label"
    :max="max"
    :rules="rules"
    @submit="$listeners.submit"
  ></VDatePickerMenu>
</template>

<script>
import VDatePickerMenu from '../../../../components/VDatePickerMenu';

import { formatDate } from '../../../../common/lang';

export default {
  components: {
    VDatePickerMenu
  },
  props: {
    value: {},
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      label: 'Datum letzte Änderung des Grundbuchblattes',
      lastChange: this.value,
      max: formatDate(new Date(), 'YYYY-MM-DD'),
      rules: [
        value => !!value || 'Geben Sie das Datum der letzten Änderung des Grundbuchblattes ein.'
      ]
    };
  },
  watch: {
    value (value) {
      if (this.lastChange !== value) {
        this.lastChange = value;
      }
    },
    lastChange (lastChange) {
      if (this.value !== lastChange) {
        this.$emit('input', lastChange);
      }
    },
    disabled (disabled) {
      if (disabled) {
        this.lastChange = null;
      }
    }
  }
};
</script>
