<template>
  <VDialog
    v-model="show"
    v-bind="$attrs"
    max-width="600px"
  >
    <VDialogContent
      title="Auskunft herunterladen"
      icon="mdi-check-circle"
      color="green"
    >
      <p>
        Die Auskunft wurde erfolgreich erstellt: <a :href="`${this.location}.pdf`" target="_blank" download>Auskunft
        herunterladen</a>
      </p>
      <p v-if="auskunft.vorkaufsrecht">
        Für Ihre Unterlagen wurde eine &Uuml;bersicht der Eingaben zur Empfangsvollmacht erstellt: <a
        :href="`${this.location}/vollmachten.pdf`" target="_blank" download>Eingaben-Empfangsvollmacht
        herunterladen</a>
      </p>

      <template #actions>
        <v-spacer></v-spacer>
        <VBtnResponsive
          color="grey lighten-3"
          depressed
          tile
          @click="show = false"
        >
          Schlie&szlig;en
        </VBtnResponsive>
      </template>
    </VDialogContent>
  </VDialog>
</template>

<script>
import VBtnResponsive from '../../../../components/VBtnResponsive';
import VDialog from '../../../../components/VDialog';
import VDialogContent from '../../../../components/VDialogContent';

export default {
  components: {
    VBtnResponsive,
    VDialog,
    VDialogContent
  },
  props: {
    auskunft: {},
    location: {},
    value: {}
  },
  data () {
    return {
      show: this.value
    };
  },
  watch: {
    value (value) {
      if (this.show !== value) {
        this.show = value;
      }
    },
    show (show) {
      if (this.value !== show) {
        this.$emit('input', show);
      }
    }
  }
};
</script>
