<template>
  <div>
    <v-banner
      :value="user.hasAnyRole([ROLE_BNOTK]) && (!user.amtstaetigkeiten || user.amtstaetigkeiten.length === 0)"
      :single-line="!$vuetify.breakpoint.xs"
      sticky
    >
      <v-icon
        slot="icon"
        color="error"
      >
        mdi-alert-circle
      </v-icon>
      Ihrer Benutzerkennung ist keine Amtst&auml;tigkeit zugeordnet. Eine Auskunft kann nicht erstellt
      werden.
    </v-banner>
    <v-container fluid>
      <v-form
        autocomplete="off"
        aria-label="Formular: Auskunft erstellen"
        ref="form"
        v-model="valid"
      >
        <v-row no-gutters justify="center">
          <v-col cols="12" md="8" xl="6">

            <v-row v-if="showAmtstitelSelect">
              <v-col>
                <VAlert>
                  Im Notarverzeichnis sind Ihnen mehrere notarielle Amtstätigkeiten zugeordnet. Bitte wählen Sie ein Amt
                  aus.
                </VAlert>
                <VSelectAmtstitel
                  v-model="auskunft.amtstaetigkeit.id"
                  :amtstaetigkeiten="user.amtstaetigkeiten"
                ></VSelectAmtstitel>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <VAlert>
                  Tragen Sie hier die Urkundenrollennummer oder ein anderes von Ihnen verwendetes Aktenzeichen ein.
                </VAlert>
                <v-text-field
                  v-model="auskunft.urkundenrolle"
                  autocomplete="off"
                  label="UR-Nr./Az."
                  maxlength="60"
                  :rules="rules.urkundenrollennummer"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <VAlert>
                  Tragen Sie hier das Grundstück/Erbbaurecht ein, das Sie in die Auskunft aufnehmen möchten.
                </VAlert>
                <SearchGrundstueck
                  ref="formGrundstueckSearch"
                  v-model="grundstueckSearch"
                  :amtstaetigkeit-id="auskunft.amtstaetigkeit ? auskunft.amtstaetigkeit.id : null"
                  :user="user"
                  :version-id="versionId"
                  @submit="addGrundstueck($event.grundstueck)"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <VAlert>
                  Die unten aufgeführten Grundstücke/Erbbaurechte sind Bestandteil Ihrer Auskunft.<br/>
                  Wählen Sie Auskunft anfordern, um eine rechtsverbindliche Auskunft als PDF-Dokument herunterzuladen.
                </VAlert>
                <v-data-table
                  v-model="selectedGrundstuecke"
                  aria-label="Tabelle: Ausgewählte Grundstücke"
                  class="mb-4"
                  disable-sort
                  disable-pagination
                  :headers="headers"
                  hide-default-footer
                  :items="auskunft.grundstuecke"
                  item-key="uid"
                  no-data-text="Keine Grundstücke ausgewählt."
                  show-select
                >
                  <template #item.grundstueck="{ item }">
                    {{ grundstueckText(item) }}
                  </template>
                  <template #item.flurstuecke="{ item }">
                    <div class="white-space-pre d-inline-block">{{ flurstueckeText(item) }}</div>
                  </template>
                  <template #item.grundbuch_modified="{ item }">
                    {{ formatDate(item.grundbuch_modified) }}
                  </template>
                </v-data-table>
                <VActionRow>
                  <VBtnResponsive
                    color="primary"
                    depressed
                    :disabled="loading"
                    :loading="loading"
                    tile
                    @click="onSubmitAuskunft"
                  >
                    Auskunft anfordern
                  </VBtnResponsive>
                  <VBtnResponsive
                    color="grey lighten-3"
                    depressed
                    tile
                    @click="removeSelectedGrundstuecke(selectedGrundstuecke)"
                  >
                    Auswahl löschen
                  </VBtnResponsive>
                  <VBtnResponsive
                    color="grey lighten-3"
                    depressed
                    tile
                    @click.prevent="dialogLoadAuskunft = true"
                  >
                    Auskunft laden
                  </VBtnResponsive>
                </VActionRow>
              </v-col>
            </v-row>

          </v-col>
        </v-row>
        <DialogPropertyOwners
          v-model="dialogPropertyOwners"
          :auskunft="auskunft"
          max-width="750px"
          persistent
          @submit="onSubmitPropertyOwners"
          @cancel="dialogPropertyOwners = false"
        />
        <DialogResult
          v-model="dialogResult"
          :auskunft="createdAuskunft"
          :location="createdAuskunftLocation"
          persistent
        />
        <DialogFindAuskunftById
          v-model="dialogLoadAuskunft"
          @item-found="onLoadAuskunft($event.item)"
        />
      </v-form>
    </v-container>
  </div>
</template>

<script>
import DialogFindAuskunftById from '../../components/DialogFindAuskunftById';
import DialogPropertyOwners from './components/DialogPropertyOwners';
import DialogResult from './components/DialogResult';
import SearchGrundstueck from './components/SearchGrundstueck';
import VActionRow from '../../components/VActionRow';
import VAlert from '../../components/VAlert';
import VBtnResponsive from '../../components/VBtnResponsive';
import VSelectAmtstitel from '../../components/VSelectAmtstitel';

import _assign from 'lodash/assign';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _pick from 'lodash/pick';
import _throttle from 'lodash/throttle';
import _uniqueId from 'lodash/uniqueId';

import {ROLE_BNOTK} from '../../config';
import {AuskunftRepository} from '../../repositories/auskunft-repository';
import {VersionRepository} from '../../repositories/version-repository';
import {equals as grundstueckEquals, flurstueckeText, text as grundstueckText} from '../../common/grundstueck';
import {formatDate, requiredRule} from '../../common/lang';

const LIMIT_GRUNDSTUECKE = 50;
const WARNING_GRUNDSTUECKE_LIMIT = `Es können maximal ${LIMIT_GRUNDSTUECKE} Grundstücke hinzugefügt werden.`;
const WARNING_GRUNDSTUECK_EXISTS = 'Das Grundstück wurde bereits der Liste hinzugefügt.';
const WARNING_GRUNDSTUECKE_EMPTY = 'Es wurden keine Grundstücke ausgewählt.';

export default {
  components: {
    DialogFindAuskunftById,
    DialogPropertyOwners,
    DialogResult,
    SearchGrundstueck,
    VActionRow,
    VAlert,
    VBtnResponsive,
    VSelectAmtstitel
  },
  props: {
    user: {}
  },
  data () {
    return {
      ROLE_BNOTK,
      versionId: null,
      auskunft: {
        amtstaetigkeit: {
          id: '',
        },
        urkundenrolle: '',
        grundstuecke: [],
        verkaeufer: [],
        vollmachtVerzicht: true,
        vollmachtAbschriftVerzicht: false,
        vollmachtAbschriftAusuebung: true
      },
      grundstueckSearch: {},
      selectedGrundstuecke: [],
      dialogLoadAuskunft: false,
      dialogPropertyOwners: false,
      dialogResult: false,
      createdAuskunft: {},
      createdAuskunftLocation: '',
      loading: false,
      valid: true,
      rules: {
        urkundenrollennummer: [
          requiredRule('Geben Sie die Urkundenrollennummer ein.'),
          value => {
            const pattern = /^[a-zA-Z0-9äöüÄÖÜßẞ ,.;/&-]+$/;
            return pattern.test(value) || 'Erlaubte Zeichen sind Buchstaben, Ziffern sowie die Zeichen: ' +
              'Komma, Punkt, Semikolon, Schrägstrich, kaufmännisches Und, Bindestrich.';
          }
        ]
      },
      headers: [
        {
          text: 'Grundstück / Erbbaurecht',
          value: 'grundstueck'
        },
        {
          text: 'Flurstück',
          value: 'flurstuecke'
        },
        {
          text: 'Buchungsart',
          value: 'buchungsart'
        },
        {
          text: 'Datum letzte Änderung des Grundbuchblattes',
          value: 'grundbuch_modified',
          width: 200
        }
      ]
    };
  },
  computed: {
    showAmtstitelSelect () {
      return !_isEmpty(this.user.amtstaetigkeiten) && this.user.amtstaetigkeiten.length > 1;
    },

    auskunftModel () {
      return {
        ..._pick(this.auskunft, [
          'urkundenrolle',
          'verkaeufer',
          'vollmachtVerzicht',
          'vollmachtAbschriftVerzicht',
          'vollmachtAbschriftAusuebung'
        ]),
        amtstaetigkeit: {
          id: this.auskunft.amtstaetigkeit
            ? this.auskunft.amtstaetigkeit.id
            : null
        },
        grundstuecke: this.auskunft.grundstuecke
          ? this.auskunft.grundstuecke.map(grundstueck => {
            return grundstueck.id
              ? {
                id: grundstueck.id,
                grundbuch_modified: grundstueck.grundbuch_modified
              }
              : {
                amtsgericht: {
                  id: grundstueck.amtsgericht.id
                },
                grundbuchbezirk: {
                  id: grundstueck.grundbuchbezirk.id
                },
                ..._pick(grundstueck, [
                  'blatt',
                  'lfdnrbestandsvz',
                  'grundbuch_modified'
                ]),
              };
          })
          : []
      };
    }
  },
  watch: {
    user: {
      handler (user) {
        if (user.amtstaetigkeiten && user.amtstaetigkeiten.length === 1) {
          this.auskunft.amtstaetigkeit.id = user.amtstaetigkeiten[0].id;
        }
      },
      immediate: true
    }
  },
  async mounted () {
    const version = await this.searchCurrentVersion();
    if (version) {
      this.versionId = version.id;
    }
  },
  methods: {
    async searchCurrentVersion () {
      try {
        return await VersionRepository.findCurrent()
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      }
    },

    addGrundstueck (grundstueck) {
      const grundstuecke = this.auskunft.grundstuecke;

      const item = {
        ...grundstueck,
        uid: _uniqueId()
      };

      if (grundstuecke.length === LIMIT_GRUNDSTUECKE) {
        this.$store.commit('SET_SNACKBAR_TEXT', WARNING_GRUNDSTUECKE_LIMIT);
      } else if (grundstuecke.some(inList => {
        return grundstueckEquals(inList, item);
      })) {
        this.$store.commit('SET_SNACKBAR_TEXT', WARNING_GRUNDSTUECK_EXISTS);
      } else {
        grundstuecke.push(item);
      }
    },

    removeSelectedGrundstuecke (selectedGrundstuecke) {
      this.auskunft.grundstuecke = this.auskunft.grundstuecke
        .filter(grundstueck => {
          return !selectedGrundstuecke.some(selected => selected.uid === grundstueck.uid);
        });
      this.selectedGrundstuecke = [];
    },

    onSubmitAuskunft: _throttle(async function () {
      if (this.$refs.form.validate()) {
        if (this.auskunft.grundstuecke.length > 0) {
          // Wenn kein Vorkaufsrecht vorliegt, kann die Auskunft direkt erstellt werden.
          if (!this.auskunft.grundstuecke.some(grundstueck => grundstueck.vorkaufsrecht && !grundstueck.grundbuch_modified)) {
            const result = await this.createAuskunft(this.auskunftModel);
            this.showAuskunftResult(result.auskunft, result.location);
            this.reset();
          } else {
            // Weitere informationen werden benötigt.
            this.dialogPropertyOwners = true;
          }
        } else {
          this.$store.commit('SET_SNACKBAR_TEXT', WARNING_GRUNDSTUECKE_EMPTY);
        }
      } else {
        this.$vuetify.goTo(this.$refs.form, {
          offset: this.$vuetify.application.top
        });
      }
    }, 450, { trailing: false }),

    async onSubmitPropertyOwners (event) {
      this.auskunft.verkaeufer = event.adressen;
      _assign(this.auskunft, event.vollmachten);
      this.dialogPropertyOwners = false;

      const result = await this.createAuskunft(this.auskunftModel);
      this.showAuskunftResult(result.auskunft, result.location);
      this.reset();
    },

    async onLoadAuskunft (auskunft) {
      if (auskunft.version_id === this.versionId) {
        // Add 'uid' to every grundstueck, this is the key used by the data-table.
        auskunft.grundstuecke = auskunft.grundstuecke.map(grundstueck => {
          return {
            ...grundstueck,
            uid: _uniqueId()
          }
        });
        this.auskunft = auskunft;
      } else {
        this.$store.commit('SHOW_INFO_DIALOG', {
          accent: 'darken-2',
          color: 'warning',
          icon: 'mdi-alert-circle',
          title: 'Warnung',
          text: 'Die Auskunft konnte nicht geladen werden. Der Datenbestand hat sich geändert.'
        });
      }
    },

    async createAuskunft (auskunft) {
      this.loading = true;
      try {
        const { headers, data } = await AuskunftRepository.create(auskunft);
        return {
          auskunft: data,
          location: headers.location
        };
      } catch (error) {
        this.$store.commit('SET_ERROR', error);
        throw error;
      } finally {
        this.loading = false;
      }
    },

    showAuskunftResult (created, location) {
      this.createdAuskunft = created;
      this.createdAuskunftLocation = location;
      this.dialogResult = true;
    },

    resetAuskunft () {
      this.auskunft = {
        amtstaetigkeit: {
          // Don't reset amtstitel.
          id: _get(this.auskunft, 'amtstaetigkeit.id', null)
        },
        urkundenrolle: '',
        grundstuecke: [],
        verkaeufer: [],
        vollmachtVerzicht: null,
        vollmachtAbschriftVerzicht: null,
        vollmachtAbschriftAusuebung: null
      };
    },

    reset () {
      this.resetAuskunft();
      this.grundstueckSearch = {};
      this.selectedGrundstuecke = [];
      this.$refs.form.resetValidation();
      this.$refs.formGrundstueckSearch.resetValidation();
    },

    grundstueckText,
    flurstueckeText,
    formatDate
  }
};
</script>
